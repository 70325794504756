<template>
  <div>

    <skeleton v-if="loading" />
    <div v-else>
      <div class="d-flex justify-content-between">
        <div class="operation-hours">
          <h5
            class="font-weight-bolder pl-2"
          >
            {{ $t('Operation Hours') }}
          </h5>
          <b-form-radio-group
            v-model="operationHourForm.levels"
            :options="levels"
            class="mb-2 operationHours"
            value-field="value"
            text-field="name"
            disabled-field="notEnabled"
            :disabled="isEdit"
            @change="handleChangeLevel"
          />
        </div>
        <feather-icon
          v-if="organizationRequiresEditConfirmation"
          v-b-tooltip.noninteractive.hover.bottom
          :title="$t('Edit')"
          :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
          size="36"
          class=" cursor-pointer"
          @click="isEditAble()"
        />
      </div>
      <operation-hours-form
        ref="operation-hours-form"
        :is-edit="isEdit"
        @refetch="getOperationHours"
      />
    </div>

  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import operationHoursModule from '@/store/settings/fulfillment/operation-hours'
import OperationHoursForm from '@/views/settings/fulfillment/operation-hours/components/OperationHoursForm.vue'
import { BFormRadioGroup, VBTooltip } from 'bootstrap-vue'
import config from './OperationHoursConfig'
import Skeleton from './components/Skeletons.vue'

export default {
  name: 'OperationHours',
  components: { BFormRadioGroup, Skeleton, OperationHoursForm },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      weekDaysOnClosed: [],
      isEdit: store.state.app.organizationRequiresEditConfirmation,
      userData: JSON.parse(localStorage.getItem('userData')),
      getRouterPath: this.$router.currentRoute.path,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    warehouseId() {
      return this.$store.state.app.warehouse?.id
    },
    operationHourForm() {
      return this.$store.state[this.MODULE_NAME].operationHourForm
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['operation-hours-form']) this.$refs['operation-hours-form'].showConfirmationModal(next)
  },
  watch: {
    warehouseId() {
      this.getOperationHours()
      this.operationHourForm.levels = this.userData?.warehouse?.hours_level ? 'warehouse_level' : 'organization_level'
    },
  },
  created() {
    this.$emit('updateMenu', this.getRouterPath.includes('fulfillment') ? 'settings-fulfillment-operation-hours' : 'settings-orders-general-rules')
    this.getOperationHours()
  },
  methods: {
    handleChangeLevel(level) {
      this.operationHourForm.levels = level

      this.$store.dispatch(`${this.MODULE_NAME}/operationHoursLevel`, {
        warehouse_id: this.warehouseId,
        works_with_organization_level: level === 'organization_level',
      }).then(() => {
        this.getOperationHours()
        const updatedUserData = {
          ...this.userData,
          warehouse: {
            ...this.userData.warehouse,
            hours_level: level === 'warehouse_level',
          },
        }
        localStorage.setItem('userData', JSON.stringify(updatedUserData))
      }).catch(err => {
        this.operationHourForm.levels = this.userData?.warehouse?.hours_level ? 'warehouse_level' : 'organization_level'
        this.errorNotification(this, err)
      })
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    getOperationHours() {
      if (!this.warehouseId) return
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getOperationHours`, {
        warehouse_id: this.warehouseId,
      }).then(res => {
        const { data: operationHourList } = res.data

        const operationHours = {}
        operationHourList.forEach((item, i) => {
          const { day, times } = item
          if (times.length === 0) {
            times.push({ closes_at: '', opens_at: '' })
          }

          const mappedTimes = times.map((time, index) => ({
            ...time,
            id: index + 1,
          }))
          operationHours[day] = { ...item, id: i, times: mappedTimes }
        })

        const values = {
          ...this.operationHourForm,
          operationHours,
          closures: this.operationHourForm.closures || [],
        }

        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, values)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/OPERATION_HOURS_FORM_CLONE_FORM_CLONE`, values)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { levels, MODULE_NAME } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, operationHoursModule)

    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      levels,
    }
  },
}
</script>

<style>
.operationHours{
  display: grid;
  gap: 8px;
}
</style>
